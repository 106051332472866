import request from '@/utils/request'

export function groupDiscountList (query) {
  return request({
    url: '/shop/group_discount/',
    method: 'get',
    params: query
  })
}
export function createGroupDiscount (parameter) {
  return request({
    url: '/shop/group_discount/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function putGroupDiscount (parameter, id) {
  return request({
    url: '/shop/group_discount/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteGroupDiscount (id) {
  return request({
    url: '/shop/group_discount/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function groupDiscountGoodsList (query) {
  return request({
    url: '/shop/group_discount_goods/',
    method: 'get',
    params: query
  })
}
export function createGroupDiscountGoods (data) {
  return request({
    url: '/shop/group_discount_goods/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function deleteGroupDiscountGoods (data) {
  return request({
    url: '/shop/group_discount_goods/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function createGroupDiscountImport (parameter) {
  return request({
    url: '/shop/group_discount_excel/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function downloadGroupDiscountExcel () {
  return request({
    url: '/shop/group_discount_excel/',
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob'
  })
}
